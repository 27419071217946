<template>
  <div>
    <w-button primary>Submit</w-button>
    <w-button primary loading>Submit</w-button>
    <w-button plain>Submit</w-button>
    <w-button plain loading>Submit</w-button>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class Home extends Vue {

  @State(state => state.auth.user) user;

  mounted() {
    if(!this.user) {
      this.$router.push('/auth/superadmin/login')
    }
  }
}
</script>
